// @flow

import _ from "lodash";
import { SHARE_CONSTANTS } from "../constants";

const CAN_USE_DOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const share = (type: string, cb: Function = _.noop) => {
  if (!CAN_USE_DOM) {
    return;
  }

  const domain = `${window.location.href}`;
  const titleProp = CAN_USE_DOM
    ? document.querySelector('meta[property="og:title"]')
    : null;
  const title: ?string = titleProp ? titleProp.getAttribute("content") : "";
  const descriptionProp = CAN_USE_DOM
    ? document.querySelector('meta[property="og:description"]')
    : null;
  const description: ?string = descriptionProp
    ? descriptionProp.getAttribute("content")
    : "";
  // const imageProp = CAN_USE_DOM ? document.querySelector('meta[property="og:image"]') : null;
  // const image: ?string = imageProp ? imageProp.getAttribute('content') : '';
  const encode = encodeURIComponent;

  if (type === SHARE_CONSTANTS.FACEBOOK) {
    const fbURL = "https://www.facebook.com/sharer.php?u=";

    window.open(`${fbURL}${encode(domain)}`, "sharer", "width=555,height=600");
  }

  if (type === SHARE_CONSTANTS.WHATSAPP) {
    console.info("wh");
    if (title && description) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.href = `whatsapp://send?text=${encode(
          `${title}\n${description}\n${domain}`
        )}`;
        console.info("else");
      } else {
        console.info("else!");
        window.open(
          `https://web.whatsapp.com/send?text=${encode(
            `${title}\n${description}\n${domain}`
          )}`,
          "_blank"
        );
      }
    }
  }
  cb();
};
