import firstSlideLeft from "./main-bg-left.jpg";
import firstSlideRight from "./main-bg-right.jpg";
import secondSlideLeft from "./second-bg-1.jpg";
import secondSlideRight from "./second-bg-2.jpg";
import thirdSlideLeft from "./third-bg-1.webp";
import thirdSlideRight from "./third-bg-2.jpg";
import fourSlideLeft from "./four-bg-1.jpg";
import fourSlideRight from "./four-bg-2.jpg";
import fiveSlideLeft from "./five-bg-1.jpg";
import fiveSlideRight from "./five-bg-2.jpg";
import arrow from "./arrow.png";

//mobile
import secondSlideLeftMobile from "./second-slide-1-mobile.png";
import secondSlideRightMobile from "./second-slide-2-mobile.png";
import thirdSlideLeftMobile from "./third-slide-1-mobile.png";
import thirdSlideRightMobile from "./third-slide-2-mobile.png";
import fourSlideLeftMobile from "./four-slide-1-mobile.png";
import fourSlideRightMobile from "./four-slide-2-mobile.png";
import fiveSlideLeftMobile from "./five-slide-1-mobile.png";
import fiveSlideRightMobile from "./five-slide-2-mobile.png";

const images = {
  firstSlideLeft,
  firstSlideRight,
  secondSlideLeft,
  secondSlideRight,
  thirdSlideRight,
  thirdSlideLeft,
  fourSlideLeft,
  fourSlideRight,
  fiveSlideLeft,
  fiveSlideRight,
  secondSlideLeftMobile,
  secondSlideRightMobile,
  thirdSlideLeftMobile,
  thirdSlideRightMobile,
  fourSlideLeftMobile,
  fourSlideRightMobile,
  fiveSlideLeftMobile,
  fiveSlideRightMobile,
  arrow,
};

export default images;
