import accessibility from "./accessibility-icon.svg";
import asus from "./asus-logo.webp";
import asusOled from "./asus-oled-logo.webp";
import facebook from "./facebook-icon.svg";
import intelI9 from "./intel-i7-logo.png";
import poweredBy from "./powered-by-logo.webp";
import scroll from "./scroll-icon.svg";
import whatsapp from "./whatsapp-icon.svg";
import ynet from "./ynet-logo.webp";
import ynetMobile from "./ynet-mobile.webp";
import shareMobile from "./shareMobile.webp";
import youtube from "./youtube-icon.svg";
import rightMenu from "./left-menu.svg";

const icons = {
  accessibility,
  asus,
  asusOled,
  facebook,
  intelI9,
  poweredBy,
  scroll,
  whatsapp,
  ynet,
  ynetMobile,
  youtube,
  shareMobile,
  rightMenu,
};

export default icons;
