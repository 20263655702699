import Home from "components/pages/Home";
import Page1 from "components/pages/Page1";
import Page2 from "components/pages/Page2";
import Page3 from "components/pages/Page3";
import Page4 from "components/pages/Page4";
import Meta from "components/common/Meta";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Meta />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/daube" component={Page1} />
        <Route exact path="/rabbi" component={Page2} />
        <Route exact path="/yambo" component={Page3} />
        <Route exact path="/sasho" component={Page4} />
      </Switch>
    </Router>
  );
}

export default App;
