// @ts-nocheck

import React from "react";

type Props = {
  image?: string;
  overlay?: boolean;
  overlayText: string;
  overlayTextClass?: string;
  children?: any;
  color?: string;
  backgroundClass?: string;
};

const FullImage = React.forwardRef(
  (
    {
      image,
      overlay,
      overlayText,
      overlayTextClass,
      children,
      color,
      backgroundClass,
    }: Props,
    ref
  ) => {
    return (
      <section
        ref={ref}
        className={`fixed bg-white h-screen w-screen bg-cover bg-center flex flex-col justify-center items-center z-20 mouse-events-none ${backgroundClass}`}
        style={{ backgroundImage: `url(${image})`, backgroundColor: color }}
      >
        {children}
      </section>
    );
  }
);

FullImage.defaultProps = {
  overlayTextClass: "",
  children: null,
  image: "",
  overlay: false,
  color: "",
  backgroundClass: "bg-top",
};

export default FullImage;
