import icons from "assets/icons";
import { useState, useEffect } from "react";
import useResponsiveQuery from "utils/useResponsiveQuery";
import Socials from "../Socialls";
import styles from "./styles.module.scss";

type Props = {
  sticky: boolean;
};

function Header(props: Props) {
  const { sticky } = props;
  let oldScrollY = 0;

  const [direction, setDirection] = useState("up");

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection("down");
    } else {
      setDirection("up");
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    if (!sticky) {
      window.addEventListener("scroll", controlDirection);
      return () => {
        window.removeEventListener("scroll", controlDirection);
      };
    }
  }, []);

  const isMobile = useResponsiveQuery();
  const leftLogos = [
    {
      icon: !isMobile ? icons.ynet : icons.ynetMobile,
      link: "https://www.ynet.co.il/",
      className: styles.ynet,
    },
    {
      icon: icons.poweredBy,
      link: "https://asus.poweredby.yit.co.il/",
      className: styles.poweredBy,
    },
    {
      icon: icons.asus,
      link: "https://www.asus.com/il/",
      className: styles.asus,
    },
    {
      icon: icons.asusOled,
      link:
        "https://www.asus.com/il/Laptops/For-Home/All-series/filter?Category=OLED-display&utm_source=ynet&utm_medium=cpc&utm_campaign=ynet_oled",
      className: styles.oled,
    },
    {
      icon: icons.intelI9,
      link: "https://asus.poweredby.yit.co.il/",
      className: styles.intel,
    },
  ];

  return (
    <div
      className={`${styles.header} ${
        direction === "down" ? styles.hidden : ""
      } fixed top-0 w-full flex justify-between`}
    >
      <div className={`${styles.left} flex`}>
        {leftLogos.map((item) => (
          <a
            href={item.link}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <img src={item.icon} alt="" className={item.className} />
          </a>
        ))}
      </div>

      <Socials minified={isMobile} />
    </div>
  );
}

export default Header;
