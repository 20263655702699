import icons from "assets/icons";
import yambo from "assets/images/yambo";
import Footer from "components/common/Footer";
import FullImage from "components/common/FullImage";
import Header from "components/common/Header";
import PromoImage from "components/common/PromoImage";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import useResponsiveQuery from "utils/useResponsiveQuery";
import styles from "./styles.module.scss";
import _ from "lodash";
import RightMenu from "components/common/RightMenu";

gsap.registerPlugin(ScrollTrigger);

function Page3() {
  const isMobile = useResponsiveQuery();
  const videoRef = useRef();
  const refs = useRef([]);
  const overlayRef = useRef();
  const triggerRefs = useRef([]);
  const paginationRefs = useRef([]);
  const slidesIndex = [4];
  const color = "#f350f8";

  const renderVideo = (src) => {
    return (
      <video
        ref={videoRef}
        src={src}
        autoPlay
        muted
        className={styles.video}
      ></video>
    );
  };
  const texts = [
    `אמן רב תחומי, מייסד ומנהל קריאייטיב של סטודיו ימבו, ומייסד של פרויקט ה-NFT דיסראפ.
    ימבו (ים בן אדיבה) הוא חלק מרשת אמנים המשתמשת במומחיות ממגוון תחומים כדי ליצור עולמות דיגיטליים בהתאמה אישית עבור לקוחות.`,
    `הסטודיו שלי בתל אביב הוא המקום שבו התחלתי ליזום את פרויקט ה-NFT דיסראפ שלי, שמחבר בין אמנות פיזית לדיגיטלית. המקום שבו אני מזגזג בין שפות ויזואליות ובונה
    עולמות אינטראקטיביים מלאי צבעים, סימנים ותמונות. טוב,
    וגם המקום שבו אני עדיין רושם לעצמי Notes עם עט בפנקס.`,
    ` חלק גדול מאוד מהעבודה שלי – וגם מהדברים שאני עושה
    סתם בשביל הנשמה – נבראים רק בזכות תוכנות עשירות של
    תלת מימד. מסוג הדברים שעבורם אין דבר כזה 'מעבד חזק
    מדי'. בכלל, כל ניסיון ליצור אמנות דיגיטלית עם מחשב שלא
   יוכל לתמוך בכל זה, זה קצת כמו לנפח בלון פורח עם קשית.`,
    `איך יודעים שזה הכלי הנכון לעבודה שלך? ובכן, אם זה מרגיש כמו הארכה של הגוף האנושי שלך, זה כבר סימן טוב. ואם הכלי הזה גם יודע מה עושה טוב לגוף האנושי 
    שלך - בכלל הסתדרת בחיים.`,
    `כי בתכלס, אני מוקף במסכים מסוגים וגדלים שונים, כמעט בכל דקה ביום העבודה שלי. אבל בלב (ובעיניים) שמורה לי פינה חמה לאלו עם טכנולוגיית ה-OLED. בהתחלה זה היה רק בזכות הצבעים העשירים והמדויקים יותר בהשוואה למסכים בטכנולוגיות אחרות, אבל עם הזמן למדתי להעריך גם את העובדה שהם פולטים כמות מופחתת של אור כחול, שנחשב כמזיק לעיניים בטווח הארוך.`,
    `חשוב לי מאוד שכל סביבת העבודה שלי, כולל הצעצועים בארגז המשחקים הדיגיטלי, יעניקו לי את החופש היצירתי שאני מחפש גם בעבודה עצמה. במילים אחרות, מסך שמתקפל לאן שרוצים ופתאום מתחפש לטאבלט, עוזר לי לשמור על עבודה אינטואיטיבית ומהירה.`,
    `הי, אני יכול ליהנות מיצירות בשחור-לבן בדיוק כמו כל אחד אחר, אבל בעבודה רוב הזמן הפנים שלי מביטות בפלטת צבעים בלתי נגמרת. אבל כדי ליהנות ממנה כמו שצריך, וחשוב מכך – כדי להגיע בדיוק לגוון שאני מחפש, צריך מסך עם ספקטרום רחב של צבעים, ברמת דיוק גבוהה במיוחד.
    כשאני מוצא בדיוק את מה שחיפשתי, אני יודע שעכשיו הקסם יתחיל.`,
  ];

  const sections = [
    {
      image: yambo.hero,
      children: (
        <>
          <h1 className={`${styles.title} text-center text-white`}>ימבו</h1>
          <h2 className={`${styles.subTitle} text-center text-white`}>
            בן 32, אמן דיגיטלי
          </h2>
          <img
            className={`${styles.scrollIcon} pointer-cursor cursor-pointer absolute inset-x-0 mx-auto`}
            src={icons.scroll}
            onClick={() => {
              if (triggerRefs) {
                triggerRefs.current[1].scrollIntoView({ behavior: "smooth" });
              }
            }}
            alt=""
          />
        </>
      ),
      text: " ",
      height: "50vh",
    },
    {
      image: yambo.hero,
      children: null,
      text: texts[0],
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? yambo.small1Mobile : yambo.small1}
            className={`${
              isMobile ? styles.imageMobile : styles.image1
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? yambo.small2Mobile : yambo.small2}
            className={`${
              isMobile ? styles.imageMobile : styles.image2
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[1],
    },
    { image: yambo.image1, children: null, text: texts[2] },
    {
      image: "",
      children: <img className={`w-full`} src={yambo.laptop} alt="" />,
      text: " ",
      color: "white",
    },
    {
      image: isMobile ? yambo.image2Mobile : yambo.image2,
      children: null,
      text: texts[3],
    },

    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? yambo.small3Mobile : yambo.small3}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:left-0`}
            alt=""
          />
          <img
            src={isMobile ? yambo.small4Mobile : yambo.small4}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:right-0`}
            alt=""
          />
        </div>
      ),
      text: texts[4],
    },
    {
      image: yambo.videoImage,
      children: isMobile
        ? null
        : renderVideo(
            "https://player.vimeo.com/external/603746027.hd.mp4?s=967642861c26f2509b9614a36b13b8aaa30226e0&profile_id=174"
          ),
      text: texts[5],
      height: "200vh",
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? yambo.small5Mobile : yambo.small5}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? yambo.small6Mobile : yambo.small6}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[6],
    },
  ];

  useEffect(() => {
    const markers = false;
    const heights = [
      -1,
      ...triggerRefs.current.map((item) => item.clientHeight),
    ];
    const getHeight = (index: number) => {
      let result = 0;
      heights.forEach((item, idx) => {
        if (idx <= index) {
          result += item;
        }
      });
      return result;
    };
    heights.forEach((item, index) => console.log(getHeight(index)));

    refs.current.forEach((item, index) => {
      gsap.fromTo(
        item,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.3,
          autoAlpha: 1,
          ease: "none",
          scrollTrigger: {
            id: `section-${index}`,

            onEnter: () => {
              if (!isMobile) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
              }
            },

            trigger: triggerRefs.current[index],
            start: index ? `top-=${heights[index] * 0.5}` : -1,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play none none reverse",
            markers: markers,
          },
        }
      );
      if (index && sections[index].text) {
        gsap.to(overlayRef.current, {
          ease: "none",
          scrollTrigger: {
            id: `overlay-${index}`,
            trigger: triggerRefs.current[index],
            start: `top-=${heights[index] * 0.5}`,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play reverse play reverse",
            markers: markers,
            onUpdate: (self) => {
              gsap.to(overlayRef.current, {
                autoAlpha: slidesIndex.includes(index)
                  ? 0
                  : self.progress < 0.45
                  ? self.progress * 2
                  : 0.9,
              });
              if (slidesIndex.includes(index)) {
                slidesIndex.forEach((slide, idx) => {
                  if (slide === index) {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: color,
                      autoAlpha: 0,
                    });
                  } else {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: "transparent",
                      autoAlpha: 0,
                    });
                  }
                });
              } else {
                slidesIndex.forEach((slide, idx) => {
                  gsap.to(paginationRefs.current[idx], {
                    autoAlpha: 0,
                  });
                });
              }
            },
          },
        });
      }
    });

    // eslint-disable-next-line
  }, [refs]);

  return (
    <>
      <Header />

      <div
        className={`${styles.home} w-screen bg-cover bg-center relative flex flex-col items-center`}
      >
        <RightMenu />

        {sections.map((item, index) => (
          <>
            <FullImage
              ref={(el) => refs.current.push(el)}
              children={item.children}
              image={item.image}
              color={_.has(item, "color") ? item.color : color}
              backgroundClass={
                _.has(item, "backgroundClass") ? item.backgroundClass : ""
              }
            />
            <div
              ref={(el) => triggerRefs.current.push(el)}
              className={`${
                index === 1 ? "pointer-events-none" : ""
              } w-full h-screen flex items-center justify-center relative z-30`}
              style={{
                height: _.has(item, "height") ? item.height : "150vh",
              }}
            >
              <div
                dir={"rtl"}
                className={`${
                  typeof item.text === "string"
                    ? `${styles.content} ${
                        isMobile ? styles.mobile : styles[`content${index}`]
                      }`
                    : ""
                } text-white text-center w-screen h-screen relative p-auto flex items-center z-30`}
              >
                {item.text}
              </div>
            </div>
          </>
        ))}
        <div
          ref={overlayRef}
          className={`w-screen h-screen fixed  z-20 pointer-events-none opacity-0`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.pagination} fixed inset-x-0 flex flex-row-reverse mx-auto justify-center z-30`}
        >
          {slidesIndex.map((item) => (
            <div
              key={item}
              ref={(el) => paginationRefs.current.push(el)}
              className={`${styles.dot} opacity-0 cursor-pointer rounded-full`}
              style={{
                border: `solid 1px ${color}`,
              }}
            />
          ))}
        </div>
        <PromoImage />
        <Footer />
      </div>
    </>
  );
}

export default Page3;
