import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Meta({ page }: Props) {
  const location = useLocation().pathname.slice(1);
  console.log(location);
  switch (location) {
    case "":
      return (
        <Helmet>
          <title>מבית היוצר</title>
          {/* <meta name="keywords" content="" /> */}
          <meta
            name="description"
            content="‏4 ימים עם 4 אנשים שמתחילים את היום עם מסך ריק ומסיימים אותו עם משהו שלא היה כאן קודם"
          />
          <meta property="og:title" content="מבית היוצר" />
          <meta
            content="‏4 ימים עם 4 אנשים שמתחילים את היום עם מסך ריק ומסיימים אותו עם משהו שלא היה כאן קודם"
            property="og:description"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://asus.poweredby.yit.co.il/share.jpeg"
          />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="מבית היוצר" />
          <meta name="robots" content="max-image-preview:large" />
          <meta property="og:url" content="https://asus.poweredby.yit.co.il" />
        </Helmet>
      );

    case "daube":
      return (
        <Helmet>
          <title>איתמר דאובה</title>
          {/* <meta name="keywords" content="" /> */}
          <meta
            name="description"
            content="איתמר דאובה - קריקטוריסט פוליטי בידיעות אחרונות. המנהל האמנותי של ערוץ הטלוויזיה babyfirst™️. מרצה בכיר וראש תוכנית הלימודים לאיור ואנימציה בשנקר."
          />
          <meta property="og:title" content="איתמר דאובה" />
          <meta
            property="og:description"
            content="איתמר דאובה - קריקטוריסט פוליטי בידיעות אחרונות. המנהל האמנותי של ערוץ הטלוויזיה babyfirst™️. מרצה בכיר וראש תוכנית הלימודים לאיור ואנימציה בשנקר."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://asus.poweredby.yit.co.il/daube-share.jpg"
          />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="איתמר דאובה" />
          <meta name="robots" content="max-image-preview:large" />
          <meta
            property="og:url"
            content="https://asus.poweredby.yit.co.il/daube"
          />
        </Helmet>
      );
    case "rabbi":
      return (
        <Helmet>
          <title>גיל רבי</title>
          {/* <meta name="keywords" content="" /> */}
          <meta
            name="description"
            content="‏גיל רבי - יזם היי-טק, מייסד סוכנות הטכנולוגיה רבי אינטראקטיב ומייסד של חברת ההזנק סטוריקארדס. אחד מחלוצי הדיגיטל בישראל ופורץ דרך בפיתוח טכנולוגיית Real Time. בין השאר הקים את המוצרים שליוו את אירוע האירוויזיון ברחבי אירופה ואת טכנולוגיית פורמט הכוכב הבא המאפשרת לצופים להרים את המסך בזמן אמת מהבית."
          />
          <meta property="og:title" content="גיל רבי" />
          <meta
            property="og:description"
            content="‏גיל רבי - יזם היי-טק, מייסד סוכנות הטכנולוגיה רבי אינטראקטיב ומייסד של חברת ההזנק סטוריקארדס. אחד מחלוצי הדיגיטל בישראל ופורץ דרך בפיתוח טכנולוגיית Real Time. בין השאר הקים את המוצרים שליוו את אירוע האירוויזיון ברחבי אירופה ואת טכנולוגיית פורמט הכוכב הבא המאפשרת לצופים להרים את המסך בזמן אמת מהבית."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://asus.poweredby.yit.co.il/rabbi-share.jpg"
          />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="גיל רבי" />
          <meta name="robots" content="max-image-preview:large" />
          <meta
            property="og:url"
            content="https://asus.poweredby.yit.co.il/rabbi"
          />
        </Helmet>
      );
    case "yambo":
      return (
        <Helmet>
          <title>ימבו</title>
          {/* <meta name="keywords" content="" /> */}
          <meta
            name="description"
            content="ימבו - אמן רב תחומי, מייסד ומנהל קריאייטיב של סטודיו ימבו, ומייסד של פרויקט ה-NFT דיסראפ. ימבו (ים בן אדיבה) הוא חלק מרשת אמנים המשתמשת במומחיות ממגוון תחומים כדי ליצור עולמות דיגיטליים בהתאמה אישית עבור לקוחות."
          />
          <meta property="og:title" content="ימבו" />
          <meta
            property="og:description"
            content="ימבו - אמן רב תחומי, מייסד ומנהל קריאייטיב של סטודיו ימבו, ומייסד של פרויקט ה-NFT דיסראפ. ימבו (ים בן אדיבה) הוא חלק מרשת אמנים המשתמשת במומחיות ממגוון תחומים כדי ליצור עולמות דיגיטליים בהתאמה אישית עבור לקוחות."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://asus.poweredby.yit.co.il/yambo-share.jpg"
          />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="ימבו" />
          <meta name="robots" content="max-image-preview:large" />
          <meta
            property="og:url"
            content="https://asus.poweredby.yit.co.il/yambo"
          />
        </Helmet>
      );
    case "sasho":
      return (
        <Helmet>
          <title>איציק שאשו</title>
          {/* <meta name="keywords" content="" /> */}
          <meta
            name="description"
            content="איציק שאשו - אחראי קריאייטיב בחברת powered by מבית ynet . כותב תסריטאי ומרצה לתוכן שיווקי באוניברסיטת תל אביב"
          />
          <meta property="og:title" content="איציק שאשו" />
          <meta
            property="og:description"
            content="איציק שאשו - אחראי קריאייטיב בחברת powered by מבית ynet . כותב תסריטאי ומרצה לתוכן שיווקי באוניברסיטת תל אביב"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://asus.poweredby.yit.co.il/sasho-share.jpg"
          />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="איציק שאשו" />
          <meta name="robots" content="max-image-preview:large" />
          <meta
            property="og:url"
            content="https://asus.poweredby.yit.co.il/sasho"
          />
        </Helmet>
      );

    default:
      break;
  }
}

export default Meta;
