// @flow

import * as React from "react";
import _ from "lodash";
import icons from "assets/icons";

import { detectIE } from "../../../utils/detectIE";

import * as css from "./AccessibilityIcon.module.scss";
const CAN_USE_DOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);
type Props = {
  onClick?: Function,
  iconColor: string,
};

class AccessibilityIcon extends React.Component<Props> {
  static defaultProps = {
    onClick: _.noop,
  };

  // eslint-disable-next-line class-methods-use-this
  get accessablityBtn() {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector("#INDmenu-btn");
  }

  onClick = (event: SyntheticEvent<*>) => {
    const { onClick } = this.props;

    if (this.accessablityBtn) {
      this.accessablityBtn.click();
    }
    if (onClick) {
      onClick(event);
    }
  };

  onMouseEnter = () => {
    let eventFocus;

    if (!CAN_USE_DOM) {
      return;
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      if (detectIE() > 0) {
        eventFocus = document.createEvent("Event");
        eventFocus.initEvent("focus", false, true);
      } else {
        eventFocus = new Event("focus");
      }

      if (this.accessablityBtn) {
        this.accessablityBtn.dispatchEvent(eventFocus);
      }
    }
  };

  onMouseLeave = () => {
    let eventBlur;

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE() > 0) {
      eventBlur = document.createEvent("Event");
      eventBlur.initEvent("blur", false, true);
    } else {
      eventBlur = new Event("blur");
    }

    if (this.accessablityBtn) {
      this.accessablityBtn.dispatchEvent(eventBlur);
    }
  };

  render() {
    return (
      <button
        type="button"
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={css.button}
      >
        <img
          className={css.accessibilityImg}
          src={icons.accessibility}
          alt=""
        />
        {/* <Icon
          type="accessibility"
          color={this.props.iconColor}
          width={isDesktop ? 38 : 38}
          height={isDesktop ? 38 : 38}
        /> */}
      </button>
    );
  }
}

export default AccessibilityIcon;
