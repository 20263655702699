import icons from "assets/icons";
import daube from "assets/images/daube";
import Footer from "components/common/Footer";
import FullImage from "components/common/FullImage";
import Header from "components/common/Header";
import RightMenu from "components/common/RightMenu";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import useResponsiveQuery from "utils/useResponsiveQuery";
import styles from "./styles.module.scss";
import "swiper/swiper-bundle.css";
import PromoImage from "components/common/PromoImage";
import _ from "lodash";

gsap.registerPlugin(ScrollTrigger);

function Page1() {
  const isMobile = useResponsiveQuery();
  const refs = useRef([]);
  const videoRef = useRef();
  const overlayRef = useRef();
  const triggerRefs = useRef([]);
  const paginationRefs = useRef([]);
  const slidesIndex = [4, 5, 6, 7, 8];
  const slidesHeight = "20vh";
  const color = "#ffd500";

  const renderVideo = (src) => {
    return (
      <video
        ref={videoRef}
        src={src}
        autoPlay
        muted
        className={styles.video}
      ></video>
    );
  };

  const texts = [
    `קריקטוריסט פוליטי בידיעות אחרונות. המנהל האמנותי של ערוץ הטלוויזיה babyfirst™️. מרצה בכיר וראש תוכנית הלימודים לאיור ואנימציה בשנקר.`,
    `לפעמים כל מה שצריך כדי להתניע את האיורים או עבודות האנימציה שלי זה פשוט לשבת ולחשוב עם עצמי. אבל לפעמים אתה לא לגמרי זוכר איך בדיוק נראה נמר. אז מתחילים מלחפש רפרנס.`,
    `יש מאיירים ואנימטורים שעדיין עושים את הסקיצה הראשונית שלהם עם עפרון ונייר. אני מעדיף עם עפרון ומחשב. זה אולי קצת פחות רומנטי ונוסטלגי, אבל מרגע שהתקדמנו שם, כבר בלתי אפשרי לחזור. בטח כשמבינים עד כמה הטכנולוגיה עצמה יכולה "לפתוח לך את הראש".`,
    `מה שכן, בלי מחשב עם מעבד מספיק חזק בשביל כל התוכנות הרלוונטיות - זה פשוט לא יעבוד. תוכנות אנימציה שורפות הרבה "דלק מחשבים" באופן יחסי, בטח אם אתה רוצה להוסיף לאיורים אלמנטים של תנועה. זאת אומרת, שמה שציירת יתחיל לזוז.`,
    `טוב, ויש את העניין עם הצבעים. והרזולוציה, והרמות של השחור, וכל רמות העומק והדיוק שבלעדיהן אפשר לאייר, אבל לא באופן שיבליט את כל היכולות שלך. אנימטורים מנוסים יודעים שגם לצבעים יש ווליום, ושגם גוונים מגיעים ב-3D, ושמסכי OLED כמו שיש ב-ASUS Zenbook יתנו לך את מה שמסכי LCD לא יכולים בזכות טווח הצבעים הרחב, העשיר והמדויק. אה כן, ועד כמה חשוב לקבל את כל זה בכל רמה של בהירות`,
    `החלק של הבהירות שעליו דיברתי מקודם? זה בגלל שלפעמים נמאס משולחן העבודה. לפעמים הבריזה של לפנות ערב נותנת לך עוד איזה שעתיים של עבודה, למרות שכבר התחילו קצת פיהוקים. לפעמים אתה לא רוצה לקחת את העבודה הביתה, אבל כן רוצה לקחת אותה לאיזה שעה בחצר.`,
    `בסופו של דבר, התהליך היצירתי עצמו לא השתנה מהותית עבורי מאז שאני מאייר ואנימטור. זה רק הכלים שהשתדרגו משמעותית. רמת האיכות שלהם, האפשרויות שהם מקנים לך, ההזדמנויות לעשות מה שאתה אוהב, בצורה טובה יותר, ומכל מקום שבא לך.`,
  ];

  const sections = [
    {
      image: daube.heroImage,
      children: (
        <>
          <h1 className={`${styles.title} text-center text-white`}>
            איתמר דאובה
          </h1>
          <h2 className={`${styles.subTitle} text-center text-white`}>
            בן 46, מאייר ואנימטור
          </h2>
          <img
            onClick={() => {
              if (triggerRefs) {
                triggerRefs.current[1].scrollIntoView({ behavior: "smooth" });
              }
            }}
            className={`${styles.scrollIcon} absolute inset-x-0 mx-auto z-50 cursor-pointer`}
            src={icons.scroll}
            alt=""
          />
        </>
      ),
      text: "",
      backgroundClass: "50vh",
      height: "50vh",
    },
    {
      image: daube.heroImage,
      children: null,
      text: texts[0],
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? daube.small1Mobile : daube.small1}
            className={`${
              isMobile ? styles.imageMobile : styles.image1
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? daube.small2Mobile : daube.small2}
            className={`${
              isMobile ? styles.imageMobile : styles.image2
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[1],
    },
    { image: daube.image1, children: null, text: texts[2], height: "75vh" },
    {
      image: "",
      children: <img className={`w-full`} src={daube.slide1} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={daube.slide2} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={daube.slide3} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={daube.slide4} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={daube.slide5} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: null,
      children: renderVideo(
        "https://player.vimeo.com/external/603725150.hd.mp4?s=e927322e1d0ca98d9b0b1825a16d69b8470b9ea9&profile_id=174"
      ),
      text: texts[3],
      height: "200vh",
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? daube.small3Mobile : daube.small3}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:left-0`}
            alt=""
          />
          <img
            src={isMobile ? daube.small4Mobile : daube.small4}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:right-0`}
            alt=""
          />
        </div>
      ),
      text: texts[4],
    },
    { image: daube.image3, children: null, text: texts[5] },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? daube.small5Mobile : daube.small5}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? daube.small6Mobile : daube.small6}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[6],
    },
  ];

  useEffect(() => {
    const markers = false;
    const heights = [
      -1,
      ...triggerRefs.current.map((item) => item.clientHeight),
    ];
    const getHeight = (index: number) => {
      let result = 0;
      heights.forEach((item, idx) => {
        if (idx <= index) {
          result += item;
        }
      });
      return result;
    };
    heights.forEach((item, index) => console.log(getHeight(index)));

    refs.current.forEach((item, index) => {
      gsap.fromTo(
        item,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.3,
          autoAlpha: 1,
          ease: "none",
          scrollTrigger: {
            id: `section-${index}`,

            onEnter: () => {
              videoRef.current.currentTime = 0;
              videoRef.current.play();
            },

            trigger: triggerRefs.current[index],
            start: index ? `top-=${heights[index] * 0.5}` : -1,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play none none reverse",
            markers: markers,
          },
        }
      );
      if (index && sections[index].text) {
        gsap.to(overlayRef.current, {
          ease: "none",
          scrollTrigger: {
            id: `overlay-${index}`,
            trigger: triggerRefs.current[index],
            start: `top-=${heights[index] * 0.5}`,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play reverse play reverse",
            markers: markers,
            onUpdate: (self) => {
              gsap.to(overlayRef.current, {
                autoAlpha: slidesIndex.includes(index)
                  ? 0
                  : self.progress < 0.45
                  ? self.progress * 2
                  : 0.9,
              });
              if (slidesIndex.includes(index)) {
                slidesIndex.forEach((slide, idx) => {
                  if (slide === index) {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: color,
                      autoAlpha: 1,
                    });
                  } else {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: "transparent",
                      autoAlpha: 1,
                    });
                  }
                });
              } else {
                slidesIndex.forEach((slide, idx) => {
                  gsap.to(paginationRefs.current[idx], {
                    autoAlpha: 0,
                  });
                });
              }
            },
          },
        });
      }
    });

    // eslint-disable-next-line
  }, [refs]);

  return (
    <>
      <Header />

      <div
        className={`${styles.home} w-screen bg-cover bg-center relative flex flex-col items-center`}
      >
        <RightMenu />
        {sections.map((item, index) => (
          <>
            <FullImage
              color={_.has(item, "color") ? item.color : color}
              ref={(el) => refs.current.push(el)}
              children={item.children}
              image={item.image}
            />
            <div
              ref={(el) => triggerRefs.current.push(el)}
              className={`${
                index === 1 ? "pointer-events-none" : ""
              } w-full h-screen flex items-center justify-center relative z-30`}
              style={{
                height: _.has(item, "height") ? item.height : "150vh",
              }}
            >
              <div
                dir={"rtl"}
                className={`${
                  typeof item.text === "string"
                    ? `${styles.content} ${
                        isMobile ? styles.mobile : styles[`content${index}`]
                      }`
                    : ""
                } text-center w-screen relative p-auto flex items-center z-30`}
              >
                {item.text}
              </div>
            </div>
          </>
        ))}
        <div
          ref={overlayRef}
          className={`w-screen h-screen fixed  z-20 pointer-events-none opacity-0`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.pagination} fixed inset-x-0 flex flex-row-reverse mx-auto justify-center z-30`}
        >
          {slidesIndex.map((item) => (
            <div
              key={item}
              ref={(el) => paginationRefs.current.push(el)}
              className={`${styles.dot} opacity-0 cursor-pointer rounded-full`}
              style={{
                border: `solid 1px ${color}`,
              }}
            />
          ))}
        </div>
        <PromoImage />
        <Footer />
      </div>
    </>
  );
}

export default Page1;
