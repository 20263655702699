import icons from "assets/icons";
import useResponsiveQuery from "utils/useResponsiveQuery";
import styles from "./styles.module.scss";

function Footer() {
  const isMobile = useResponsiveQuery();
  return (
    <>
      <footer
        dir="rtl"
        className={`${styles.footer} w-full text-white absolute bottom-0 z-50 flex items-center justify-center`}
      >
        <div className={`${styles.right} `}>
          <a
            href="https://z.ynet.co.il/short/content/2018/PrivacyPolicy/poweredbypolicy.html"
            target="_blank"
            rel="noreferrer"
          >
            <p className={`whitespace-nowrap`}>תנאי שימוש ומדיניות פרטיות</p>
          </a>
        </div>
        <div className={`${styles.left} flex items-center`}>
          <p className={`whitespace-nowrap`}>איפיון ועיצוב</p>
          <a
            href="https://poweredby.yit.co.il/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={icons.poweredBy} alt="" />
          </a>
        </div>
      </footer>
      {!isMobile && (
        <div className={`${styles.footer} w-full bg-black z-40`}></div>
      )}
    </>
  );
}

export default Footer;
