import * as React from "react";
import classNames from "classnames";
import icons from "assets/icons";
import { SHARE_CONSTANTS } from "../../../constants";

// import { share } from "client/utils/share";
// import { IS_DEVEL } from "common/constants";
import { dataLayerEvents } from "utils/gtag";
// import { SHARE_CONSTANTS } from "client/constants";
// import { Icon } from "../Icon";
import { share } from "../../../utils/share";
import * as css from "./Socialls.module.scss";
import AccessibilityIcon from "../AccessibilityIcon";

export default class Socials extends React.Component {
  static defaultProps = {
    className: "",
    minified: false,
  };

  state = {
    isOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.sociallsRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.documentClickListener);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.documentClickListener);
  }

  documentClickListener = (e: MouseEvent) => {
    if (this.sociallsRef.current) {
      if (this.sociallsRef.current.contains((e.target: any))) return false;
    }

    this.setState({
      isOpen: false,
    });
  };

  toggleOpenMenu = (e: SyntheticEvent<HTMLDivElement>) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
    const type = e.currentTarget.dataset.id;
    // events.onShareClick(type, this.props.shareCategory);
    dataLayerEvents.onShare(type);
    share(type);
  };

  render() {
    const { className, minified } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="flex">
        {/* <button className={css.accessibility}>
          <img
            className={css.accessibilityImg}
            src={icons.accessibility}
            alt=""
          />
        </button> */}
        <AccessibilityIcon />
        <div
          className={classNames(
            css.socials,
            this.state.isOpen && css.open,
            minified && css.minified,
            className
          )}
          ref={this.sociallsRef}
        >
          <div className={css.overflowWrap}>
            <button
              type="button"
              className={css.socialButton}
              data-id={SHARE_CONSTANTS.FACEBOOK}
              onClick={this.onShare}
            >
              <img src={icons.facebook} alt=""></img>
              {/* <Icon
              type="whatsapp_icon"
              width={32}
              height={32}
              className={css.iconWhatsapp}
            /> */}
            </button>
            <button
              className={css.socialButton}
              type="button"
              data-id={SHARE_CONSTANTS.WHATSAPP}
              onClick={this.onShare}
            >
              <img src={icons.whatsapp} alt=""></img>
            </button>
          </div>
          {minified && (
            <div className={css.toggleBtn} onClick={this.toggleOpenMenu}>
              <svg
                data-name="Group 592"
                xmlns="http://www.w3.org/2000/svg"
                width="28.709"
                height="28.711"
              >
                <g data-name="Group 570">
                  <g data-name="Group 577">
                    <g data-name="Path 22">
                      <path
                        data-name="Path 2600"
                        d="M1781.783 20a14.355 14.355 0 1 0 14.417 14.3 14.354 14.354 0 0 0-14.417-14.3z"
                        transform="translate(-1767.487 -20)"
                        style={{ fill: "#fff" }}
                      />
                      <path
                        data-name="Path 2601"
                        d="M1781.845 20a14.356 14.356 0 0 1 .055 28.711h-.058a14.356 14.356 0 0 1-.057-28.711z"
                        transform="translate(-1767.49 -20)"
                        style={{ fill: `${isOpen ? "#000" : "#fff"}` }}
                      />
                    </g>
                    <path
                      style={{ fill: `${!isOpen ? "#000" : "#fff"}` }}
                      data-name="Icon material-share"
                      d="M18.212 17.583a2 2 0 0 0-1.343.528l-4.885-2.844a1.9 1.9 0 0 0 0-.959l4.83-2.816a2.05 2.05 0 0 0 3.453-1.5.742.742 0 0 0 .02-.5 2.448 2.448 0 0 0-.361-.634 2.186 2.186 0 0 0-1.714-.922 2.053 2.053 0 0 0-2.055 2.055 2.243 2.243 0 0 0 .062.48l-4.831 2.816a2.055 2.055 0 1 0 0 3l4.878 2.85a1.933 1.933 0 0 0-.055.445 2 2 0 1 0 2-2z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          )}
        </div>
      </div>
    );
  }
}
