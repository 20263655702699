import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import _ from "lodash";
import css from "./Loader.module.scss";
import gsap from "gsap";

const Loader = () => {
  const loaderTextDiv = useRef();
  const [loaderDisable, setLoaderDisable] = useState(false);
  const [display, setDisplay] = useState(true);
  const title = "מבית היוצר";
  const textSplit = title.split("");

  setTimeout(() => setLoaderDisable(true), 3000);
  setTimeout(() => setDisplay(false), 4600);

  useEffect(() => {
    const letters1 = document.querySelectorAll(".letter");
    let secondTl = gsap.timeline();
    let masterTl = gsap.timeline({
      onComplete: () => secondTl.play().delay(0.5),
    });
    Array.from(letters1).forEach((el, idx) => {
      const y = idx % 2 ? 250 : -250;
      let tl = gsap.timeline();
      tl.fromTo(el, { y: y }, { y: 0, duration: 0.2, ease: "power3.inOut" });
      secondTl.to(el, { y: y, duration: 0.1, ease: "power3.inOut" }).pause();

      masterTl.add(tl);
    });
  }, []);

  return (
    <div
      className={cn(
        css.loader,
        loaderDisable && css.disable,
        !display && css.displayNone
      )}
    >
      <div className={css.innerLoader}>
        <div ref={loaderTextDiv} className={css.loaderText}>
          {_.map(textSplit, (letter, index) => {
            return (
              <p
                key={`letter-${index}`}
                className="letter"
                style={{ display: "block", whiteSpace: "pre" }}
              >
                {letter}
              </p>
            );
          })}
        </div>
        <div className={css.circle}></div>
        <div className={css.innerCircle}></div>
      </div>
    </div>
  );
};

export default Loader;
