import icons from "assets/icons";
import rabbi from "assets/images/rabbi";
import Footer from "components/common/Footer";
import FullImage from "components/common/FullImage";
import Header from "components/common/Header";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import useResponsiveQuery from "utils/useResponsiveQuery";
import styles from "./styles.module.scss";
import _ from "lodash";
import PromoImage from "components/common/PromoImage";
import RightMenu from "components/common/RightMenu";

gsap.registerPlugin(ScrollTrigger);

function Page2() {
  const isMobile = useResponsiveQuery();
  const videoRef = useRef();
  const refs = useRef([]);
  const overlayRef = useRef();
  const triggerRefs = useRef([]);
  const paginationRefs = useRef([]);
  const slidesIndex = [4, 5, 6, 7];
  const slidesHeight = "20vh";
  const color = "#3bffb1";

  const renderVideo = (src) => {
    return (
      <video
        ref={videoRef}
        src={src}
        autoPlay
        muted
        className={styles.video}
      ></video>
    );
  };

  const texts = [
    `יזם היי-טק, מייסד סוכנות הטכנולוגיה רבי אינטראקטיב ומייסד של חברת ההזנק סטוריקארדס. אחד מחלוצי הדיגיטל בישראל ופורץ דרך בפיתוח טכנולוגיית Real Time. בין השאר הקים את המוצרים שליוו את אירוע האירוויזיון ברחבי אירופה ואת טכנולוגיית פורמט הכוכב הבא המאפשרת לצופים להרים את המסך בזמן אמת מהבית.`,
    `אני מסוג היזמים שחייבים שליטה על התוצרים. חוטא בכך שאני מוצא את עצמי כותב שורות קוד שלמות מחדש כדי להגיע לתוצר שאני שלם איתו וזה תמיד בא על חשבון שעות שינה. כמו שאני מתחפש ומחליף תפקידים בין המתכנן והמבצע, כך חשוב לי גם מחשב שיודע לשנות צורה. המעבר בין מצב של תכנון תהליך הפיתוח וארכיטקטורה, למצב של פיתוח וכתיבת קוד היו טובים יותר בזכות המחשב המיוחד הזה. בזכות האפשרות להמשיך לתכנן את הפיתוח בצורה קצת שונה עם מצב טאבלט שנוח ליצירת שרטוטים ותרשימים - ואז תמיד לחזור לכתיבת הקוד עצמו במצב הרגיל.`,
    `האחריות הזו של להחזיק מוצרים שמשתמשים בהם כ-5 מיליון אנשים מדי חודש מצריכה ממני להיות מספיק גמיש וזמין עם יכולות מלאות מכל מקום, וזה כבר די סטנדרטי אצלי שאני מוצא את עצמי עוצר את הרכב באמצע הנסיעה ופותח את המחשב כדי לטפל במשברים (או בשפה שלנו בבאגים) שלא עלינו עליהם בבדיקות גרסה. כשאתה מסתובב עם לפטופ בתדירות שבה אחרים מסתובבים עם הטלפון שלהם, אתה חייב שהוא יהיה מספיק קל, נוח וגם מספיק מרשים כדי שתוכל להניח אותו על השולחן וכולם יבואו לשאול מה זה.`,
    `מאז ומתמיד הייתי מאלה שצריכים מסכים איכותיים כדי לעבוד, זה לא רק הבריאות והאור הכחול הידוע לשמצה שנפלט מהמסכים ומעייף את העיניים. זה גם שאני יכול להיכנס לאתגר שאני חייב לפתור, ולעבוד לתוך הלילה עם שעות רבות מול המסך. הטכנולוגיה של ה- ZenBook Pro ומסך ה-OLED שלו מאפשרת לי לעשות את זה ולהרגיש בזון טוב לאורך כל השעות, עם מסך שמפחית 70% מהאור הכחול שרע לעיניים שלנו מבלי להתפשר על רמת הדיוק והחדות של הצבעים.`,
    `אגב, אחד הדברים הכי משמעותיים עבורי הם השילוב של החומרה עם מערכת ההפעלה לינוקס. לא מעט לפטופים שניסיתי פשוט לא ידעו להשתמש בחומרה בצורה נכונה בשילוב עם לינוקס. במקרה של אסוס זה פשוט מושלם עם סוללה מנצחת ומעבד שמאפשר לי להריץ כל דבר שהייתי צריך בלי להרגיש שאני מכביד עליו.`,
    `חלק מהדיסוננס הזה בין יזם למפתח תמיד מביא אותי למצבים שאני מרגיש שכל זמן שאני לא עובד או לא עושה משהו חיוני יכול היה להיות מוקדש לעוד כמה שורות קוד ולקידום המוצר. לכן ישנה חשיבות עליונה למחשב שבאמת אפשר לעבוד איתו ונוח לקחת בלי להרגיש. כזה שאפשר לשלוף, להדליק, ולהיות זמין לעבוד עוד קצת בכל דקה פנויה, כן, גם אם התחלתי לעבוד לפני 14 שעות ככה.`,
  ];

  const sections = [
    {
      image: isMobile ? rabbi.heroMobile : rabbi.hero,
      children: (
        <>
          <h1 className={`${styles.title} text-center text-white`}>גיל רבי</h1>
          <h2 className={`${styles.subTitle} text-center text-white`}>
            בן 34, מפתח ויזם הייטק
          </h2>
          <img
            className={`${styles.scrollIcon} pointer-cursor cursor-pointer absolute inset-x-0 mx-auto`}
            src={icons.scroll}
            onClick={() => {
              if (triggerRefs) {
                triggerRefs.current[1].scrollIntoView({ behavior: "smooth" });
              }
            }}
            alt=""
          />
        </>
      ),
      text: " ",
      height: "50vh",
    },
    {
      image: isMobile ? rabbi.heroMobile : rabbi.hero,
      children: null,
      text: texts[0],
      backgroundClass: "bg-center",
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? rabbi.small1Mobile : rabbi.small1}
            className={`${
              isMobile ? styles.imageMobile : styles.image1
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? rabbi.small2Mobile : rabbi.small2}
            className={`${
              isMobile ? styles.imageMobile : styles.image2
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[1],
    },
    {
      image: rabbi.videoImage,
      children: isMobile
        ? null
        : renderVideo(
            "https://player.vimeo.com/external/603738425.hd.mp4?s=f3c6baf3e6ef4321bd88016b3da0b07cec386dce&profile_id=174"
          ),
      text: texts[2],
      height: "200vh",
    },
    {
      image: "",
      children: <img className={`w-full`} src={rabbi.slide1} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={rabbi.slide2} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={rabbi.slide3} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={rabbi.slide4} alt="" />,
      text: " ",
      color: "white",
      height: "75vh",
    },

    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? rabbi.small3Mobile : rabbi.small3}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:left-0`}
            alt=""
          />
          <img
            src={isMobile ? rabbi.small4Mobile : rabbi.small4}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:right-0`}
            alt=""
          />
        </div>
      ),
      text: texts[3],
      height: "200vh",
    },
    {
      image: isMobile ? rabbi.image1Mobile : rabbi.image1,
      children: null,
      text: texts[4],
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? rabbi.small5Mobile : rabbi.small5}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:left-0`}
            alt=""
          />
          <img
            src={isMobile ? rabbi.small6Mobile : rabbi.small6}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:right-0`}
            alt=""
          />
        </div>
      ),
      text: texts[5],
    },
  ];

  useEffect(() => {
    const markers = false;
    const heights = [
      -1,
      ...triggerRefs.current.map((item) => item.clientHeight),
    ];
    const getHeight = (index: number) => {
      let result = 0;
      heights.forEach((item, idx) => {
        if (idx <= index) {
          result += item;
        }
      });
      return result;
    };
    heights.forEach((item, index) => console.log(getHeight(index)));

    refs.current.forEach((item, index) => {
      gsap.fromTo(
        item,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.3,
          autoAlpha: 1,
          ease: "none",
          scrollTrigger: {
            id: `section-${index}`,

            onEnter: () => {
              if (!isMobile) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
              }
            },

            trigger: triggerRefs.current[index],
            start: index ? `top-=${heights[index] * 0.5}` : -1,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play none none reverse",
            markers: markers,
          },
        }
      );
      if (index && sections[index].text) {
        gsap.to(overlayRef.current, {
          ease: "none",
          scrollTrigger: {
            id: `overlay-${index}`,
            trigger: triggerRefs.current[index],
            start: `top-=${heights[index] * 0.5}`,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play reverse play reverse",
            markers: markers,
            onUpdate: (self) => {
              gsap.to(overlayRef.current, {
                autoAlpha: slidesIndex.includes(index)
                  ? 0
                  : self.progress < 0.45
                  ? self.progress * 2
                  : 0.9,
              });
              if (slidesIndex.includes(index)) {
                slidesIndex.forEach((slide, idx) => {
                  if (slide === index) {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: color,
                      autoAlpha: 1,
                    });
                  } else {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: "transparent",
                      autoAlpha: 1,
                    });
                  }
                });
              } else {
                slidesIndex.forEach((slide, idx) => {
                  gsap.to(paginationRefs.current[idx], {
                    autoAlpha: 0,
                  });
                });
              }
            },
          },
        });
      }
    });

    // eslint-disable-next-line
  }, [refs]);

  return (
    <>
      <Header />

      <div
        className={`${styles.home} w-screen bg-cover bg-center relative flex flex-col items-center`}
      >
        <RightMenu />

        {sections.map((item, index) => (
          <>
            <FullImage
              ref={(el) => refs.current.push(el)}
              children={item.children}
              image={item.image}
              color={_.has(item, "color") ? item.color : color}
              backgroundClass={
                _.has(item, "backgroundClass") ? item.backgroundClass : ""
              }
            />
            <div
              ref={(el) => triggerRefs.current.push(el)}
              className={`${
                index === 1 ? "pointer-events-none" : ""
              } w-full h-screen flex items-center justify-center relative z-30`}
              style={{
                height: _.has(item, "height") ? item.height : "150vh",
              }}
            >
              <div
                dir={"rtl"}
                className={`${
                  typeof item.text === "string"
                    ? `${styles.content} ${
                        isMobile ? styles.mobile : styles[`content${index}`]
                      }`
                    : ""
                } text-center w-screen h-screen relative p-auto flex items-center z-30`}
              >
                {item.text}
              </div>
            </div>
          </>
        ))}
        <div
          ref={overlayRef}
          className={`w-screen h-screen fixed  z-20 pointer-events-none opacity-0`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.pagination} fixed inset-x-0 flex flex-row-reverse mx-auto justify-center z-30`}
        >
          {slidesIndex.map((item) => (
            <div
              key={item}
              ref={(el) => paginationRefs.current.push(el)}
              className={`${styles.dot} opacity-0 cursor-pointer rounded-full`}
              style={{
                border: `solid 1px ${color}`,
              }}
            />
          ))}
        </div>
        <PromoImage />
        <Footer />
      </div>
    </>
  );
}

export default Page2;
