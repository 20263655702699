import hero from "./hero-image.jpg";
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image2Mobile from "./image2-mobile.jpg";
import small1 from "./small1.jpg";
import small2 from "./small2.jpg";
import small3 from "./small3.jpg";
import small4 from "./small4.jpg";
import small5 from "./small5.jpg";
import small6 from "./small6.jpg";
import small1Mobile from "./small1-mobile.jpg";
import small2Mobile from "./small2-mobile.jpg";
import small3Mobile from "./small3-mobile.jpg";
import small4Mobile from "./small4-mobile.jpg";
import small5Mobile from "./small5-mobile.jpg";
import small6Mobile from "./small6-mobile.jpg";
import laptop from "./laptop.jpg";
import slide1 from "./slide1.webp";
import slide2 from "./slide2.jpg";
import slide3 from "./slide3.jpg";
import slide4 from "./slide4.jpg";
import slide5 from "./slide5.jpg";
import slide6 from "./slide6.webp";
import slide7 from "./slide7.jpg";
import promoImage from "./promo-image.jpg";

const images = {
  hero,
  image1,
  image2,
  image2Mobile,
  small1,
  small2,
  small3,
  small4,
  small5,
  small6,
  small1Mobile,
  small2Mobile,
  small3Mobile,
  small4Mobile,
  small5Mobile,
  small6Mobile,
  laptop,
  promoImage,
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
};
export default images;
