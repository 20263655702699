import React, { useState } from "react";
import css from "./RightMenu.module.scss";
import cn from "classnames";
import icons from "assets/icons";
import useResponsiveQuery from "utils/useResponsiveQuery";

const RightMenu = () => {
  const isMobile = useResponsiveQuery();
  const [hover, setHover] = useState(isMobile ? !isMobile : isMobile);
  const [isMinimized, setIsMinimized] = useState(!isMobile);
  return (
    <div
      onMouseEnter={() => setHover(isMobile ? false : true)}
      onMouseLeave={() => {
        setHover(false);
        setIsMinimized(false);
      }}
      className={cn(
        css.rightMenu,
        isMinimized ? css.isMinimized : "",
        hover ? css.hover : ""
      )}
    >
      <button
        type="button"
        onClick={() => {
          if (isMobile) {
            setIsMinimized(!isMinimized);
            setHover(!hover);
          } else {
            window.location.href = "/";
          }
        }}
        className={cn(
          css.minimizedButton,
          hover ? css.hover : "",
          isMinimized ? css.isMinimized : ""
        )}
      >
        <img src={icons.rightMenu} alt="menu" className={css.first}></img>
      </button>
      <div className={cn(css.links, isMinimized ? css.isMinimized : "")}>
        <a
          href="https://asus.poweredby.yit.co.il/daube"
          className={cn(css.circle, css.yellow, hover ? css.hover : "")}
        >
          <span>איתמר דאובה</span>
        </a>
        <a
          href="https://asus.poweredby.yit.co.il/rabbi"
          className={cn(css.circle, css.green, hover ? css.hover : "")}
        >
          <span>גיל רבי</span>
        </a>
        <a
          href="https://asus.poweredby.yit.co.il/yambo"
          className={cn(css.circle, css.purple, hover ? css.hover : "")}
        >
          <span>ימבו</span>
        </a>
        <a
          href="https://asus.poweredby.yit.co.il/sasho"
          className={cn(css.circle, css.blue, hover ? css.hover : "")}
        >
          <span>איציק שאשו</span>
        </a>
      </div>
    </div>
  );
};

export default RightMenu;
