import hero from "./hero.webp";
import heroMobile from "./hero-mobile.webp";
import image1 from "./image-1.webp";
import image1Mobile from "./image1-mobile.jpg";
import laptop from "./laptop-2.jpg";
import small1 from "./small-1.webp";
import small2 from "./small-2.webp";
import small3 from "./small-3.jpg";
import small4 from "./small-4.webp";
import small5 from "./small-5.webp";
import small6 from "./small-6.jpg";
import small1Mobile from "./small1-mobile.jpg";
import small2Mobile from "./small2-mobile.jpg";
import small3Mobile from "./small3-mobile.jpg";
import small4Mobile from "./small4-mobile.jpg";
import small5Mobile from "./small5-mobile.jpg";
import small6Mobile from "./small6-mobile.jpg";
import slide1 from "./slide1.jpg";
import slide2 from "./slide2.jpg";
import slide3 from "./slide3.jpg";
import slide4 from "./slide4.jpg";
import videoImage from "./video-image.jpg";
import promoImage from "./promo-image.jpg";

const images = {
  hero,
  heroMobile,
  image1,
  image1Mobile,
  laptop,
  small1,
  small2,
  small3,
  small4,
  small5,
  small6,
  small1Mobile,
  small2Mobile,
  small3Mobile,
  small4Mobile,
  small5Mobile,
  small6Mobile,
  slide1,
  slide2,
  slide3,
  slide4,
  videoImage,
  promoImage,
};

export default images;
