import React, { useEffect, useRef, useState } from "react";
import page3 from "assets/images/home";
import useResponsiveQuery from "utils/useResponsiveQuery";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { gsap } from "gsap";
import cn from "classnames";
import "prevent-pull-refresh";
import css from "./styles.module.scss";
import TitleWithCircle from "components/common/TitleWithCircle";
import Header from "components/common/Header";
import Loader from "components/common/Loader";
import Footer from "components/common/Footer";
import RightMenu from "components/common/RightMenu";

const Home = () => {
  const isMobile = useResponsiveQuery();
  const [index, setIndex] = useState(0);
  const firstSlide1 = useRef();
  const firstSlide2 = useRef();
  const secondSlide1 = useRef();
  const secondSlide2 = useRef();
  const thirdSlide1 = useRef();
  const thirdSlide2 = useRef();
  const fourSlide1 = useRef();
  const fourSlide2 = useRef();
  const fiveSlide1 = useRef();
  const fiveSlide2 = useRef();

  const durationAnimation = 1;

  // Up scroll handler
  const upHandler = () => {
    if (index === 0) return false;
    return setIndex(index - 1);
  };

  // Down scroll handler
  const downHandler = () => {
    if (index === 4) return false;
    return setIndex(index + 1);
  };

  const gsapAnimationScroll = (
    slide: any,
    rotation: Number,
    delay: Number = 0
  ) => {
    return gsap.to(slide, {
      ease: "expo.inOut",
      rotation: rotation,
      duration: durationAnimation,
      delay: delay,
    });
  };

  const nextSlide = () => {
    if (index === 4) return false;
    setIndex(index + 1);
  };

  useEffect(() => {
    // For slider work. Configuration rotation for all slides when scroll up/down
    if (index === 0) {
      gsapAnimationScroll(firstSlide1.current, 0, 0.2);
      gsapAnimationScroll(firstSlide2.current, 0);
    } else if (index === 1) {
      gsapAnimationScroll(firstSlide1.current, 180, 0.3);
      gsapAnimationScroll(firstSlide2.current, 180, 0.2);
      gsapAnimationScroll(secondSlide1.current, 0, 0.2);
      gsapAnimationScroll(secondSlide2.current, 0, 0.3);
      gsapAnimationScroll(thirdSlide1.current, -180, 0.2);
    } else if (index === 2) {
      gsapAnimationScroll(secondSlide2.current, 180);
      gsapAnimationScroll(thirdSlide1.current, 0, 0.3);
      gsapAnimationScroll(thirdSlide2.current, 0, 0.3);
      gsapAnimationScroll(fourSlide2.current, -180, 0.2);
    } else if (index === 3) {
      gsapAnimationScroll(thirdSlide1.current, 180, 0.2);
      gsapAnimationScroll(fourSlide2.current, 0, 0.3);
      gsapAnimationScroll(fiveSlide1.current, -180, 0.2);
    } else if (index === 4) {
      gsapAnimationScroll(fourSlide2.current, 180, 0.2);
      gsapAnimationScroll(fiveSlide1.current, 0, 0.3);
    }
  }, [index]);

  return (
    <div className={css.homeWrapper}>
      <ReactScrollWheelHandler
        upHandler={upHandler}
        downHandler={downHandler}
        customStyle={{
          height: "100vh",
        }}
        timeout={1200}
      >
        {isMobile && <RightMenu />}
        <Loader />
        <Header sticky />
        <TitleWithCircle index={index} />
        {index !== 4 && (
          <button className={css.arrowButton} onClick={nextSlide}>
            <img alt="arrow" src={page3.arrow} className={css.arrow} />
          </button>
        )}

        <div className={css.circle}>
          <figure className={css.chart} data-percent="0">
            <svg>
              <circle
                className={cn(
                  css.outer,
                  index === 1 && css.activeCircle1,
                  index === 2 && css.activeCircle2,
                  index === 3 && css.activeCircle3,
                  index === 4 && css.activeCircle4
                )}
                cx="-5"
                cy={!isMobile ? "200" : "196"}
                r={!isMobile ? "189" : "150"}
                transform="rotate(-90, 95, 95)"
              />
            </svg>
          </figure>
        </div>

        <div className={css.sections}>
          {/* --- First section --- */}
          <section className={cn(css.firstSlide, css.sliders)}>
            <div className={cn(css.firstSlideLeft, css.slide)}>
              <div
                ref={firstSlide1}
                className={cn(css.innerSlide, css.innerSlideLeft)}
                style={{ backgroundImage: `url(${page3.firstSlideLeft})` }}
              ></div>
            </div>
            <div className={cn(css.firstSlideRight, css.slide)}>
              <div
                ref={firstSlide2}
                className={cn(css.innerSlide, css.innerSlideRight)}
                style={{ backgroundImage: `url(${page3.firstSlideRight})` }}
              ></div>
            </div>
          </section>

          {/* --- Second section --- */}
          <section className={cn(css.secondSlide, css.sliders)}>
            <div
              className={cn(
                css.secondSlideLeft,
                index === 1 && css.active,
                css.slide
              )}
            >
              <div
                ref={secondSlide1}
                className={cn(css.innerSlide, css.innerSlideLeft)}
                style={{
                  backgroundImage: `url(${
                    !isMobile
                      ? page3.secondSlideLeft
                      : page3.secondSlideLeftMobile
                  })`,
                }}
              ></div>
            </div>
            <div
              className={cn(
                css.secondSlideRight,
                index === 1 && css.active,
                css.slide
              )}
            >
              <div
                ref={secondSlide2}
                className={cn(css.innerSlide, css.innerSlideRight)}
                style={{
                  backgroundImage: `url(${
                    !isMobile
                      ? page3.secondSlideRight
                      : page3.secondSlideRightMobile
                  })`,
                }}
              ></div>
            </div>
          </section>

          {/* --- Third section --- */}
          <section className={cn(css.thirdSlide, css.sliders)}>
            <div
              className={cn(
                css.thirdSlideLeft,
                index === 2 && css.active,
                css.slide
              )}
            >
              <div
                ref={thirdSlide1}
                className={cn(css.innerSlide, css.innerSlideLeft)}
                style={{
                  backgroundImage: `url(${
                    !isMobile
                      ? page3.thirdSlideLeft
                      : page3.thirdSlideLeftMobile
                  })`,
                }}
              ></div>
            </div>
            <div
              className={cn(
                css.thirdSlideRight,
                index === 2 && css.active,
                css.slide
              )}
            >
              <div
                ref={thirdSlide2}
                className={cn(css.innerSlide, css.innerSlideRight)}
                style={{
                  backgroundImage: `url(${
                    !isMobile
                      ? page3.thirdSlideRight
                      : page3.thirdSlideRightMobile
                  })`,
                }}
              ></div>
            </div>
          </section>

          {/* --- Four section --- */}

          <section className={cn(css.fourSlide, css.sliders)}>
            <div
              className={cn(
                css.fourSlideLeft,
                index === 3 && css.active,
                css.slide
              )}
            >
              <div
                ref={fourSlide1}
                className={cn(css.innerSlide, css.innerSlideLeft)}
                style={{
                  backgroundImage: `url(${
                    !isMobile ? page3.fourSlideLeft : page3.fourSlideLeftMobile
                  })`,
                }}
              ></div>
            </div>
            <div
              className={cn(
                css.fourSlideRight,
                index === 3 && css.active,
                css.slide
              )}
            >
              <div
                ref={fourSlide2}
                className={cn(css.innerSlide, css.innerSlideRight)}
                style={{
                  backgroundImage: `url(${
                    !isMobile
                      ? page3.fourSlideRight
                      : page3.fourSlideRightMobile
                  })`,
                }}
              ></div>
            </div>
          </section>
          <section className={cn(css.fiveSlide, css.sliders)}>
            <div
              className={cn(
                css.fiveSlideLeft,
                index === 4 && css.active,
                css.slide
              )}
            >
              <div
                ref={fiveSlide1}
                className={cn(css.innerSlide, css.innerSlideLeft)}
                style={{
                  backgroundImage: `url(${
                    !isMobile ? page3.fiveSlideLeft : page3.fiveSlideLeftMobile
                  })`,
                }}
              ></div>
            </div>
            <div
              className={cn(
                css.fiveSlideRight,
                index === 4 && css.active,
                css.slide
              )}
            >
              <div
                ref={fiveSlide2}
                className={cn(css.innerSlide, css.innerSlideRight)}
                style={{
                  backgroundImage: `url(${
                    !isMobile
                      ? page3.fiveSlideRight
                      : page3.fiveSlideRightMobile
                  })`,
                }}
              ></div>
            </div>
          </section>
        </div>
      </ReactScrollWheelHandler>
      <Footer />
    </div>
  );
};

export default Home;
