import React, { useState, useEffect } from "react";
import css from "./TitleWithCircle.module.scss";
import cn from "classnames";
import useResponsiveQuery from "../../../utils/useResponsiveQuery";

type Props = {
  index: number,
};

const TitleWithCircle = (props: Props) => {
  const { index } = props;
  const isMobile = useResponsiveQuery();
  const [timeoutIndex, setTimeoutIndex] = useState();
  const firstMobileDescription = (
    <>
      ,‏4 ימים עם 4 אנשים <br />
      שמתחילים את היום עם מסך ריק
      <br />
      ומסיימים אותו עם משהו <br />
      שלא היה כאן קודם
    </>
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setTimeoutIndex(index);
    }, 1100);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [index]);

  return (
    <div className={css.title}>
      <div className={css.innerTitleBlock}>
        <h1
          className={cn(css.title, css.firstTitle, index === 0 && css.active)}
        >
          מבית היוצר
        </h1>
        <a
          className={cn(timeoutIndex === 1 && css.activeTitle, css.link)}
          href="/daube"
        >
          <h1
            className={cn(
              css.title,
              css.leftTransform,
              index === 1 && css.active,
              css.secondTitle
            )}
          >
            איתמר
            <br /> דאובה
          </h1>
        </a>
        <a
          className={cn(timeoutIndex === 2 && css.activeTitle, css.link)}
          href="/rabbi"
        >
          <h1
            className={cn(
              css.title,
              css.rightTransform,
              index === 2 && css.active
            )}
          >
            גיל רבי
          </h1>
        </a>
        <a
          className={cn(timeoutIndex === 3 && css.activeTitle, css.link)}
          href="/yambo"
        >
          <h1
            className={cn(
              css.title,
              css.leftTransform,
              index === 3 && css.active
            )}
          >
            ימבו
          </h1>
        </a>
        <a
          className={cn(timeoutIndex === 4 && css.activeTitle, css.link)}
          href="/sasho"
        >
          <h1
            className={cn(
              css.title,
              css.bottomTransform,
              index === 4 && css.active,
              css.lastTitle
            )}
          >
            איציק
            <br />
            שאשו
          </h1>
        </a>

        <p
          className={cn(
            css.description,
            css.bottomTransform,
            index === 0 && css.active,
            css.firstDescription
          )}
        >
          {!isMobile ? (
            <>
              ,‏4 ימים עם 4 אנשים שמתחילים את היום עם מסך ריק
              <br />
              ומסיימים אותו עם משהו שלא היה כאן קודם
            </>
          ) : (
            firstMobileDescription
          )}
        </p>
        <p
          className={cn(
            css.description,
            css.rightTransform,
            index === 1 && css.active,
            css.secondDescription
          )}
        >
          מאייר ואנימטור
        </p>
        <p
          className={cn(
            css.description,
            css.leftTransform,
            index === 2 && css.active,
            css.thirdDescription
          )}
        >
          מפתח ויזם הייטק
        </p>
        <p
          className={cn(
            css.description,
            css.rightTransform,
            index === 3 && css.active,
            css.fourDescription
          )}
        >
          אמן דיגיטלי
        </p>
        <p
          className={cn(
            css.description,
            css.bottomTransform,
            index === 4 && css.active,
            css.fiveDescription
          )}
        >
          קריאייטיב ותסריטאי
        </p>
      </div>
    </div>
  );
};

export default TitleWithCircle;
