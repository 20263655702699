import images from "assets/images";
import React from "react";
import useResponsiveQuery from "utils/useResponsiveQuery";
import styles from "./styles.module.scss";

function PromoImage() {
  const isMobile = useResponsiveQuery();

  return (
    <div className={`w-screen flex relative z-30 flex-col justify-end`}>
      <a
        target="_blank"
        className={`w-full`}
        href="https://ksp.co.il/web/cat/271?search=OLED&utm_source=ynet&utm_medium=cpc&utm_campaign=ynet_ksp"
        rel="noreferrer"
      >
        <img
          className={`w-full`}
          src={isMobile ? images.promoImageMobile : images.promoImage}
          alt=""
        />
      </a>
      {isMobile && <div className={`${styles.paddingMobile} `}></div>}
    </div>
  );
}
export default PromoImage;
