import icons from "assets/icons";
import shasho from "assets/images/shasho";
import Footer from "components/common/Footer";
import FullImage from "components/common/FullImage";
import Header from "components/common/Header";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import useResponsiveQuery from "utils/useResponsiveQuery";
import styles from "./styles.module.scss";
import PromoImage from "components/common/PromoImage";
import _ from "lodash";
import RightMenu from "components/common/RightMenu";

gsap.registerPlugin(ScrollTrigger);

function Page4() {
  const isMobile = useResponsiveQuery();
  const videoRef = useRef();
  const videoSrc =
    "https://player.vimeo.com/external/603741024.hd.mp4?s=cd3cd1acd934371b81129ff99470c479c3d93174&profile_id=174";

  const refs = useRef([]);
  const overlayRef = useRef();
  const triggerRefs = useRef([]);
  const paginationRefs = useRef([]);
  const slidesIndex = [4, 5, 6, 7, 8, 9, 10];
  const slidesHeight = "20vh";
  const color = "#20ffff";

  const texts = [
    `אחראי קריאייטיב בחברת powered by מבית ynet.
    כותב, תסריטאי ומרצה לתוכן שיווקי באוניברסיטת תל אביב`,
    `הקומיקאי המנוח מיטש הדברג סיפר שלפעמים היתה קופצת לו לראש בדיחה מעולה, אבל לא היה לו כוח לחפש עכשיו דף ועט כדי לכתוב אותה לפני שישכח, אז הוא פשוט היה משכנע את עצמו שבעצם היא לא כל כך מצחיקה. אז אנחנו כבר מזמן בעידן דיגיטלי ולא צריכים ניירות או עפרונות, אבל עדיין צריכים מחשב עם סוללה שתצליח להתחשב ברגעים האלה שקפץ לך משהו לראש שאתה חייב לכתוב עכשיו. כי לי - בניגוד למיטש הדברג – קשה לוותר על פאנץ' מוצלח.`,
    `זה נכון שיחד עם השיער שמאפיר, גם הפונט שבו אני כותב התחיל לגדול בשנים האחרונות, אבל להגנתי ייאמר שאני מעביר את רוב יומי בחברת אותיות מהבהבות, ואם קצת מתפשרים בכל מה שקשור ללפטופ, סיכוי סביר שבסוף היום העיניים שלך יזכירו לך את זה. חוץ מזה, אתה תמיד רוצה מסך שלא מפחד מעבודה בכל תנאי תאורה ובהירות שיש.`,
    `החדשות הטובות בקשר לכתיבה – תסריטאית, אבל לא רק – היא שלגמרי מדובר בשריר שאפשר לפתח אם מתאמנים. טקסט של 500 מילה שפעם היה לוקח שלוש שעות, היום לרוב גמור אצלי כעבור שעה גג, כולל כותרות ועניינים. זאת בדיוק הסיבה שאחרי הפסקות ארוכות מדי, כותבים מוצאים את עצמם בוהים במסך יותר מדי זמן בלי לכתוב אפילו אות. בקיצור, חשוב להתאמן. לפחות שרירי ידיים, כן?`,
    `טוב, אני בטח לא אפיל אף אחד מהכיסא אם אגיד שאחת הבעיות של עבודה מהבית, היא כל שאר הדברים בבית שלא ממש קשורים לעבודה. אני מדבר על הספה שלפעמים נוחה מדי, המקרר שנמצא ממש כמה מטרים ממך, ואולי האויב הכי גדול: תוכן מעולה בלחיצת כפתור, ובאיכות סאונד ותמונה מדהימה. אז לפעמים אני מספר לעצמי שזה למטרות השראה, אבל בתוך תוכי אני יודע שסתם המצאתי פה משהו כדי לתרץ לעצמי את הנטפליקס הזה באמצע היום. אגב, הצבעים השחורים העמוקים במסכי ה-OLED של ASUS אשכרה מאפשרים לי לקלוט פרטים שלא ראיתי בעבר בסצנות חשוכות שחשבתי שאני מכיר בסרטים או בסדרות.`,
    `אז האם כדי לכתוב את הרומן הגדול הבא (או את הפרסומת הבאה לאבקת מרק כלשהי) אפשר להסתפק גם במחשב נייח ישן וקובץ וורד מרצד? אני מניח שכן. אבל אני מאמין שהימים של הכותבים המיוסרים במרתף ביתם הסתיימו מזמן, והיום כדי להוציא מעצמך את המקסימום אתה צריך שיהיה לך נוח במקסימום. וכן, זה כולל גם את המחשב שבו אתה משתמש. שיהיה קליל, פונקציונלי, מתקדם וידידותי. ואם אפשר גם שיעזור לך למצוא סלוגן טוב למרק שממש לא מרגישים שמכינים מאבקה. `,
  ];

  const sections = [
    {
      image: shasho.hero,
      children: (
        <>
          <h1 className={`${styles.title} text-center text-white`}>
            איציק שאשו
          </h1>
          <h2 className={`${styles.subTitle} text-center text-white`}>
            בן 45, קריאייטיב ותסריטאי
          </h2>
          <img
            className={`${styles.scrollIcon} pointer-cursor cursor-pointer absolute inset-x-0 mx-auto`}
            src={icons.scroll}
            onClick={() => {
              if (triggerRefs) {
                triggerRefs.current[1].scrollIntoView({ behavior: "smooth" });
              }
            }}
            alt=""
          />
        </>
      ),
      text: " ",
      height: "50vh",
    },
    {
      image: shasho.hero,
      children: null,
      text: texts[0],
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? shasho.small1Mobile : shasho.small1}
            className={`${
              isMobile ? styles.imageMobile : styles.image1
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? shasho.small2Mobile : shasho.small2}
            className={`${
              isMobile ? styles.imageMobile : styles.image2
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[1],
    },
    { image: shasho.image1, children: null, text: texts[2] },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide1} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide2} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide3} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide4} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide5} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide6} alt="" />,
      text: " ",
      color: "white",
      height: slidesHeight,
    },
    {
      image: "",
      children: <img className={`w-full`} src={shasho.slide7} alt="" />,
      text: " ",
      color: "white",
      height: "100vh",
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? shasho.small3Mobile : shasho.small3}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={isMobile ? shasho.small4Mobile : shasho.small4}
            className={`${
              isMobile ? styles.imageMobile : styles.image5
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />
        </div>
      ),
      text: texts[3],
    },
    {
      image: "",
      children: (
        <div
          className={`w-full h-full flex flex-col items-center justify-around`}
        >
          <img
            src={isMobile ? shasho.small5Mobile : shasho.small5}
            className={`${
              isMobile ? styles.imageMobile : styles.image6
            } lg:absolute lg:top-0 lg:right-0`}
            alt=""
          />
          <img
            src={shasho.small6Mobile}
            className={`${isMobile ? styles.imageMobile : styles.image5} ${
              !isMobile && "hidden"
            } lg:absolute lg:bottom-0 lg:left-0`}
            alt=""
          />

          <video
            ref={videoRef}
            className={`${styles.image5} ${
              isMobile && "hidden"
            } absolute bottom-0 left-0`}
            muted
            autoPlay
            src={videoSrc}
          ></video>
        </div>
      ),
      text: texts[4],
    },
    {
      image: isMobile ? shasho.image2Mobile : shasho.image2,
      children: null,
      text: texts[5],
    },
  ];

  useEffect(() => {
    const markers = false;
    const heights = [
      -1,
      ...triggerRefs.current.map((item) => item.clientHeight),
    ];
    const getHeight = (index: number) => {
      let result = 0;
      heights.forEach((item, idx) => {
        if (idx <= index) {
          result += item;
        }
      });
      return result;
    };
    heights.forEach((item, index) => console.log(getHeight(index)));

    refs.current.forEach((item, index) => {
      gsap.fromTo(
        item,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.3,
          autoAlpha: 1,
          ease: "none",
          scrollTrigger: {
            id: `section-${index}`,
            onEnter: () => {
              videoRef.current.currentTime = 7;
              videoRef.current.play();
            },
            trigger: triggerRefs.current[index],
            start: index ? `top-=${heights[index] * 0.5}` : -1,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play none none reverse",
            markers: markers,
          },
        }
      );
      if (index && sections[index].text) {
        gsap.to(overlayRef.current, {
          ease: "none",
          scrollTrigger: {
            id: `overlay-${index}`,
            trigger: triggerRefs.current[index],
            start: `top-=${heights[index] * 0.5}`,
            end: `bottom-=${heights[index + 1] * 0.5}`,
            toggleActions: "play reverse play reverse",
            markers: markers,
            onUpdate: (self) => {
              gsap.to(overlayRef.current, {
                autoAlpha: slidesIndex.includes(index)
                  ? 0
                  : self.progress < 0.45
                  ? self.progress * 2
                  : 0.9,
              });
              if (slidesIndex.includes(index)) {
                slidesIndex.forEach((slide, idx) => {
                  if (slide === index) {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: color,
                      autoAlpha: 1,
                    });
                  } else {
                    gsap.to(paginationRefs.current[idx], {
                      backgroundColor: "transparent",
                      autoAlpha: 1,
                    });
                  }
                });
              } else {
                slidesIndex.forEach((slide, idx) => {
                  gsap.to(paginationRefs.current[idx], {
                    autoAlpha: 0,
                  });
                });
              }
            },
          },
        });
      }
    });

    // eslint-disable-next-line
  }, [refs]);

  return (
    <>
      <Header />

      <div
        className={`${styles.home} w-screen bg-cover bg-center relative flex flex-col items-center`}
      >
        <RightMenu />

        {sections.map((item, index) => (
          <>
            <FullImage
              ref={(el) => refs.current.push(el)}
              children={item.children}
              image={item.image}
              color={_.has(item, "color") ? item.color : color}
              backgroundClass={
                _.has(item, "backgroundClass") ? item.backgroundClass : ""
              }
            />
            <div
              ref={(el) => triggerRefs.current.push(el)}
              className={`${
                index === 1 ? "pointer-events-none" : ""
              } w-full h-screen flex items-center justify-center relative z-30`}
              style={{
                height: _.has(item, "height") ? item.height : "150vh",
              }}
            >
              <div
                dir={"rtl"}
                className={`${
                  typeof item.text === "string"
                    ? `${styles.content} ${
                        isMobile ? styles.mobile : styles[`content${index}`]
                      }`
                    : ""
                } text-center w-screen h-screen relative p-auto flex items-center z-30`}
              >
                {item.text}
              </div>
            </div>
          </>
        ))}
        <div
          ref={overlayRef}
          className={`w-screen h-screen fixed  z-20 pointer-events-none opacity-0`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className={`${styles.pagination} fixed inset-x-0 flex flex-row-reverse mx-auto justify-center z-30`}
        >
          {slidesIndex.map((item) => (
            <div
              key={item}
              ref={(el) => paginationRefs.current.push(el)}
              className={`${styles.dot} opacity-0 cursor-pointer rounded-full`}
              style={{
                border: `solid 1px ${color}`,
              }}
            />
          ))}
        </div>
        <PromoImage />
        <Footer />
      </div>
    </>
  );
}

export default Page4;
